export const LEAVE_BALANCE_REQUEST = "LEAVE_BALANCE_REQUEST";
export const LEAVE_BALANCE_SUCCESS = "LEAVE_BALANCE_SUCCESS";
export const LEAVE_BALANCE_FAIL = "LEAVE_BALANCE_FAIL";

export const NOT_ENTERED_LEAVE_REQUEST = "NOT_ENTERED_LEAVE_REQUEST";
export const NOT_ENTERED_LEAVE_SUCCESS = "NOT_ENTERED_LEAVE_SUCCESS";
export const NOT_ENTERED_LEAVE_FAIL = "NOT_ENTERED_LEAVE_FAIL";

export const PUNCTUALITY_REQUEST = "PUNCTUALITY_REQUEST";
export const PUNCTUALITY_SUCCESS = "PUNCTUALITY_SUCCESS";
export const PUNCTUALITY_FAIL = "PUNCTUALITY_FAIL";

export const LEAVE_SUMMERY_REQUEST = "LEAVE_SUMMERY_REQUEST";
export const LEAVE_SUMMERY_SUCCESS = "LEAVE_SUMMERY_SUCCESS";
export const LEAVE_SUMMERY_FAIL = "LEAVE_SUMMERY_FAIL";