export const QR_REQUEST = 'QR_REQUEST'
export const QR_SUCCESS = 'QR_SUCCESS'
export const QR_FAIL = 'QR_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_MESSAGE = "SET_MESSAGE";

export const GET_EWO_DETAILS_REQUEST = "GET_EWO_DETAILS_REQUEST";
export const GET_EWO_DETAILS_SUCCESS = "GET_EWO_DETAILS_SUCCESS";
export const GET_EWO_DETAILS_FAIL = "GET_EWO_DETAILS_FAIL";

export const RECEIVE_EWO_DETAILS_REQUEST = "RECEIVE_EWO_DETAILS_REQUEST";
export const RECEIVE_EWO_DETAILS_SUCCESS = "RECEIVE_EWO_DETAILS_SUCCESS";
export const RECEIVE_EWO_DETAILS_FAIL = "RECEIVE_EWO_DETAILS_FAIL";

export const SEND_EWO_DETAILS_REQUEST = "SEND_EWO_DETAILS_REQUEST";
export const SEND_EWO_DETAILS_SUCCESS = "SEND_EWO_DETAILS_SUCCESS";
export const SEND_EWO_DETAILS_FAIL = "SEND_EWO_DETAILS_FAIL";

export const IS_OPEN = "IS_OPEN";
export const IS_CLOSE = "IS_CLOSE";
