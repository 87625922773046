export const GET_USER_MEDICAL_DETAILS_REQUEST = "GET_USER_MEDICAL_DETAILS_REQUEST";
export const GET_USER_MEDICAL_DETAILS_SUCCESS = "GET_USER_MEDICAL_DETAILS_SUCCESS";
export const GET_USER_MEDICAL_DETAILS_FAIL = "GET_USER_MEDICAL_DETAILS_FAIL";

export const GET_MEDICAL_INDOOR_USAGE_DETAILS_REQUEST = "GET_MEDICAL_INDOOR_USAGE_DETAILS_REQUEST";
export const GET_MEDICAL_INDOOR_USAGE_DETAILS_SUCCESS = "GET_MEDICAL_INDOOR_USAGE_DETAILS_SUCCESS";
export const GET_MEDICAL_INDOOR_USAGE_DETAILS_FAIL = "GET_MEDICAL_INDOOR_USAGE_DETAILS_FAIL";

export const GET_MEDICAL_OUTDOOR_USAGE_DETAILS_REQUEST = "GET_MEDICAL_OUTDOOR_USAGE_DETAILS_REQUEST";
export const GET_MEDICAL_OUTDOOR_USAGE_DETAILS_SUCCESS = "GET_MEDICAL_OUTDOOR_USAGE_DETAILS_SUCCESS";
export const GET_MEDICAL_OUTDOOR_USAGE_DETAILS_FAIL = "GET_MEDICAL_OUTDOOR_USAGE_DETAILS_FAIL";