
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../action/Login";
import UserProfile from "../../layouts/userProfile/userProfile";
const Header = ({ title }) => {
  const [hasImage, setHasImage] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const authKey = JSON.parse(localStorage.getItem("token"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { data, loading, msg } = useSelector((state) => state.userbyServiceNo);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", "#004AAD");
      }
      const img = new Image();
      img.onload = function () {
        setHasImage(true);
      };
      img.onerror = function () {
        setHasImage(false);
      };
      img.src = `${
        axios.defaults.baseURL
      }home/GetUserImg?authKey=${authKey.replace("+", "%2B")}`.replace(
        /"/g,
        ""
      );
    } catch (error) {}
  }, [navigate, authKey]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logOut(navigate));
  };

  const greet = () => {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      return "Good Morning...";
    } else if (time >= 12 && time <= 16) {
      return "Good Afternoon...";
    } else if (time >= 17 && time <= 24) {
      return "Good Evening...";
    }
  };

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      // onClick={toggleDrawer}
      // onKeyDown={toggleDrawer}
    >
      <div className="vh-100" style={{ backgroundColor: "#eee" }}>
        <Container className="container py-5 h-100">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <CancelIcon />
            </IconButton>
          </div>

          <Grid
            container
            className="justify-content-center align-items-center h-100"
          >
            <Grid item md={12} xl={4}>
              <Card style={{ borderRadius: "15px" }}>
                <CardContent className="text-center">
                  <div
                    className="mt-3 mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <CardMedia
                      component="img"
                      alt="Profile Image"
                      // src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"

                      src={
                        hasImage
                          ? `${
                              axios.defaults.baseURL
                            }home/GetUserImg?authKey=${authKey.replace(
                              "+",
                              "%2B"
                            )}`.replace(/"/g, "")
                          : require("../../assets/images/man.png")
                      }
                      style={{ width: "80%", borderRadius: 10 }}
                    />
                  </div>
                  <Typography variant="h6">
                    {data.length > 0 ? data[0].ReportName : ""}
                  </Typography>
                  <Typography variant="body2" className="text-muted mb-4">
                    {data.length > 0 ? data[0].Designation : ""}{" "}
                    <span className="mx-2">|</span>{" "}
                    <a href="#!"> {data.length > 0 ? data[0].Email : ""}</a>
                  </Typography>

                  <div className="d-flex justify-content-between text-center mt-2 mb-5">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        //  alignContent: "center",
                        justifyContent: "center",
                        alignContent: "space-between",
                      }}
                    >
                      <Typography variant="h7">Division : </Typography>
                      <Typography variant="h7">
                        {data.length > 0 ? data[0].Division : ""}
                      </Typography>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ borderRadius: "30px" }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        padding: 1,
        top: 0,
        zIndex: 999,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <div style={{ maxWidth: "400px" }}>
          <img
            width={"60%"}
            src={require("../../assets/icons/a.png")}
            alt="First slide"
          />
        </div>
        <Typography fontSize={12} fontWeight={550}>
          {greet()}
        </Typography>
        {/* <Typography fontSize={10} fontWeight={200}>
       hello.. W.L.H.M.A.A. BANDARA
        </Typography> */}
      </Box>
      <Box sx={{ alignItems: "center" }}>
        <IconButton onClick={toggleDrawer}>
          <Avatar
            //alt="Remy Sharp"
            variant="rounded"
            src={
              hasImage
                ? `${
                    axios.defaults.baseURL
                  }home/GetUserImg?authKey=${authKey.replace(
                    "+",
                    "%2B"
                  )}`.replace(/"/g, "")
                : require("../../assets/images/man.png")
            }
            sx={{
              width: 40,
              height: "20%",
              borderRadius: 2,
            }}
          />
        </IconButton>

        <SwipeableDrawer
          anchor={"right"}
          open={visible}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          {list()}
        </SwipeableDrawer>
        {/* <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu> */}
      </Box>
    </Box>
  );
};

export default Header;
